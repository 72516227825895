/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx } from 'theme-ui'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'

const VideoSection = ({ children }) => {
  return (
    <Box bg="white" py={[9, 14]}>
      <Container>
        <Grid columns={[1, 12]}>
          <Box
            sx={{
              gridColumn: [null, '2 / 12'],
            }}
          >
            <VisuallyHidden>
              <Heading as="h2" variant="title2" mb={2}>
                Video
              </Heading>
            </VisuallyHidden>
            {children}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default VideoSection
