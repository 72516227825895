/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Container, Grid, Text, jsx } from 'theme-ui'

const PackDescriptors = ({ descriptors }) => {
  if (!descriptors) {
    return <Fragment />
  }

  return (
    <Box bg="white">
      <Container>
        <Grid
          as="ul"
          columns={[null, null, descriptors.length]}
          gap={0}
          m={0}
          px={0}
          py={[3, null, 4]}
        >
          {descriptors.map((descriptor, index) => (
            <Box
              key={index}
              as="li"
              px={[0, null, 1]}
              py={[3, null, 5]}
              sx={{
                listStyleType: 'none',
                textAlign: 'center',
                '&:not(:last-child)': {
                  borderRight: [null, null, '1px solid'],
                  borderRightColor: [null, null, 'greyLight'],
                },
              }}
            >
              <Text as="strong" variant="callout1">
                {descriptor}
              </Text>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default PackDescriptors
