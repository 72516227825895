/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import ContentSection from '~/components/Content/Section'
import PackDescriptors from './Descriptors'
import PackDetails from './Details'
import PackFaq from './Faq'
import PackRelatedListings from './RelatedListings'
import PackProductsInfoMixed from './ProductsInfo/Mixed'
import PackProductsInfoSpecs from './ProductsInfo/Specs'
import PackProductsInfoVarietal from './ProductsInfo/Varietal'
import Reviews from '~/components/Reviews'
import SpotlightSection from '~/components/Content/Section/Spotlight'
import VideoSection from '~/components/Content/Section/Video'
import YoutubeVideo from '~/components/Generic/YoutubeVideo'

const PackPage = ({ pack }) => {
  const {
    descriptors,
    faq,
    hideProductList,
    packType,
    partnerSpotlight,
    relatedListings,
    reviewsPack,
    sections,
    slug,
    video,
  } = pack

  return (
    <Fragment>
      <PackDetails pack={pack} />
      <PackDescriptors descriptors={descriptors} />
      {partnerSpotlight && (
        <SpotlightSection
          heading={'Partner Spotlight'}
          title={partnerSpotlight.title}
          subtitle={partnerSpotlight.subtitle}
          description={partnerSpotlight.description}
          image={partnerSpotlight.media}
        />
      )}
      {packType === 'Varietal' && <PackProductsInfoVarietal pack={pack} />}
      {packType === 'Mixed' && !hideProductList && (
        <PackProductsInfoMixed pack={pack} />
      )}
      {['Gift', 'Event'].includes(packType) && !hideProductList && (
        <PackProductsInfoSpecs pack={pack} packType={packType} />
      )}
      {video && (
        <VideoSection>
          <YoutubeVideo id={video} />
        </VideoSection>
      )}
      <PackFaq faq={faq} />
      <PackRelatedListings listings={relatedListings} />
      {sections &&
        sections.map((section) => {
          return <ContentSection key={section.id} section={section} />
        })}
      <Reviews id={reviewsPack?.slug || slug} />
    </Fragment>
  )
}

export default PackPage
