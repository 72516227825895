/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import { Box, Button, Container, Grid, Heading, Text, jsx } from 'theme-ui'
import { useAuth, useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import ListingGiftMessage from '~/components/Listing/GiftMessage'
import PackCallout from '~/components/Pack/Callout'
import PackDetailsAddToCart from './AddToCart'
import PackDetailsUpsell from './Upsell'
import PackImages from '~/components/Pack/Page/Details/Images'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import LinkedReviewStars from '~/components/Reviews/LinkedStars'
import { useCanClub } from '~/hooks/components/use-can-club'
import { useCatalog } from '~/hooks/components/use-catalog'
import { getLoginUrl } from '~/utils/auth'
import { getAvailablePackKits } from '~/utils/packs'
import { getViewedProductFromSlug } from '~/utils/products'

const PackDetails = ({ pack }) => {
  const {
    additionalDetails,
    canClubOnly,
    comingSoon,
    extendedDescription,
    images,
    included,
    longDescription,
    mainImage,
    name,
    preorderDate,
    packType,
    reviewsPack,
    slug,
    soldOut,
    subtitle,
  } = pack
  const availableKits = getAvailablePackKits(pack)

  const packImages =
    images && images.length ? [mainImage, ...images] : [mainImage]

  const { trackProductViewed } = useAnalytics()
  const { isLoggedIn } = useAuth()
  const { clubDiscount } = useCanClub()
  const { catalog } = useCatalog()
  const [isDescriptionExtended, setIsDescriptionExtended] = useState(false)

  const preorderLabel =
    preorderDate &&
    moment(preorderDate).isAfter(moment()) &&
    `Ships ${moment(preorderDate).format('MMMM Do')}`

  const viewedProduct = getViewedProductFromSlug(catalog, slug)

  useEffect(() => {
    trackProductViewed({ product: viewedProduct })
  }, [trackProductViewed, viewedProduct])

  return (
    <Container>
      <Box
        pb={[7, 9, null, 10]}
        pt={[0, 7, null, 9]}
        sx={{
          display: [null, 'grid'],
          gridTemplateAreas: [null, "'images text'"],
          gridTemplateColumns: [null, 'repeat(2, 1fr)'],
          columnGap: 3,
        }}
      >
        <Box
          mb={3}
          mx={-2}
          sx={{
            display: ['block', 'none'],
            gridArea: [null, 'images'],
            position: 'relative',
            '& .carousel-slider': {
              overflow: 'visible',
            },
            '& .slider-wrapper': {
              overflow: 'visible',
            },
          }}
        >
          <ImageCarousel images={packImages} />
          <Box sx={{ left: 0, position: 'absolute', top: 0 }}>
            <PackCallout pack={pack} />
          </Box>
        </Box>
        <Box
          sx={{
            display: ['none', 'block'],
            gridArea: [null, 'images'],
          }}
        >
          <PackImages images={packImages} pack={pack} />
        </Box>
        <Box sx={{ gridArea: [null, 'text'] }}>
          <Heading as="h1" variant="title2" mb={2}>
            {name}
          </Heading>
          {subtitle && (
            <Text as="h2" variant="subtitle1" mb={[2, null, 3]}>
              {subtitle}
            </Text>
          )}
          <LinkedReviewStars id={reviewsPack?.slug || slug} mb={[2, null, 3]} />
          {longDescription && (
            <Box
              dangerouslySetInnerHTML={{
                __html: longDescription.childMarkdownRemark.html,
              }}
              sx={{
                '& p': {
                  my: 2,
                  variant: 'text.body2',
                },
                '& p:first-of-type': {
                  mt: 0,
                },
                '& p:last-of-type': {
                  mb: 0,
                },
              }}
            />
          )}
          {extendedDescription && (
            <Box>
              {isDescriptionExtended && (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: extendedDescription.childMarkdownRemark.html,
                  }}
                  sx={{
                    mt: 2,
                    '& p': {
                      variant: 'text.body2',
                      my: 2,
                    },
                    '& p:first-of-type': {
                      mt: 0,
                    },
                    '& p:last-of-type': {
                      mb: 0,
                    },
                  }}
                />
              )}
              <Button
                variant="reset"
                mt={1}
                onClick={(e) =>
                  setIsDescriptionExtended(!isDescriptionExtended)
                }
                sx={{
                  span: {
                    variant: 'text.body2',
                    color: 'brandPrimary',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                }}
              >
                <Text
                  as="span"
                  sx={{ display: isDescriptionExtended ? 'block' : 'none' }}
                >
                  {'Show less'}
                </Text>
                <Text
                  as="span"
                  sx={{ display: !isDescriptionExtended ? 'block' : 'none' }}
                >
                  {'Read more'}
                </Text>
              </Button>
            </Box>
          )}
          {additionalDetails && (
            <Box
              bg="white"
              p={2}
              dangerouslySetInnerHTML={{
                __html: additionalDetails.childMarkdownRemark.html,
              }}
              sx={{
                mt: 3,
                '& p': {
                  variant: 'text.body2',
                  my: 0,
                },
              }}
            />
          )}
          <Box
            mt={3}
            pt={3}
            sx={{
              borderTop: availableKits.length > 0 ? '1px solid' : null,
              borderTopColor: 'greyLight',
            }}
          >
            {included && (
              <Box mb={[2, 3]}>
                <Heading as="h2" variant="title5" mb={1}>
                  {"What's included:"}
                </Heading>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: included.childMarkdownRemark.html,
                  }}
                  sx={{
                    '& p': {
                      variant: 'text.body2',
                      my: 0,
                    },
                  }}
                />
              </Box>
            )}
            {packType === 'Gift' && (
              <Box mb={3}>
                <ListingGiftMessage />
              </Box>
            )}
            {!soldOut && !comingSoon && (!canClubOnly || clubDiscount > 0) && (
              <Fragment>
                <PackDetailsAddToCart pack={pack} />
                {preorderLabel && (
                  <Box bg="white" mt={1} py="12px" px={3}>
                    <Text
                      as="p"
                      variant="callout1"
                      sx={{ m: 0, textAlign: 'center' }}
                    >
                      {preorderLabel}
                    </Text>
                  </Box>
                )}
                {!preorderLabel &&
                  clubDiscount === 0 &&
                  availableKits.length > 0 && (
                    <Grid columns={[1, 2]} gap={[2, 3]} mt={[2, null, 3]}>
                      <PackDetailsUpsell>
                        <Text as="span">
                          <strong>Free shipping</strong> on orders of 12+ cans
                        </Text>
                      </PackDetailsUpsell>
                      <PackDetailsUpsell>
                        <Text as="span">Can Club members save up to 20%</Text>
                      </PackDetailsUpsell>
                    </Grid>
                  )}
                {!preorderLabel && clubDiscount > 0 && (
                  <Box
                    bg="backgroundMedium"
                    mt={2}
                    py={2}
                    px={3}
                    sx={{ textAlign: 'center' }}
                  >
                    <Text as="p" variant="body3" m={0}>
                      {`Your ${clubDiscount}% club discount has been applied.`}
                    </Text>
                  </Box>
                )}
                {packType === 'Event' && (
                  <Text
                    as="p"
                    mt={2}
                    mb={0}
                    variant="description"
                    color="greyDark"
                  >
                    {
                      'Event link and Calendar invitations will be sent a few days after registration. An adult 21+ must be present for delivery.'
                    }
                  </Text>
                )}
              </Fragment>
            )}
            {!soldOut && !comingSoon && canClubOnly && !clubDiscount && (
              <Fragment>
                <Box bg="white" py={3} px={[2, 3]} sx={{ textAlign: 'center' }}>
                  <Text as="p" variant="callout1" mb={3}>
                    This wine is only available to Can Club members
                  </Text>
                  <Button as={Link} to="/can-club/">
                    {'Join the club'}
                  </Button>
                </Box>
                {!isLoggedIn && (
                  <Text as="p" variant="body3" m={0} mt={3}>
                    If you're already a Can Club member,{' '}
                    <Link to={`/${getLoginUrl()}/`}>
                      <Text
                        as="span"
                        sx={{
                          color: 'brandPrimary',
                          textDecoration: 'underline',
                        }}
                      >
                        sign in
                      </Text>
                    </Link>{' '}
                    for your discount and access to members only wines.
                  </Text>
                )}
              </Fragment>
            )}
            {(soldOut || comingSoon) && (
              <ProductOutOfStock
                sku={availableKits?.[0]?.products?.[0]?.variants?.[0]?.sku}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default PackDetails
