import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ProductListSection from '~/components/Content/Section/ProductList'

const PackRelatedListings = ({ listings = [] }) => {
  const translate = useTranslate()

  if (!listings) {
    return <Fragment />
  }

  return (
    <ProductListSection
      title={translate('product.related.title')}
      listings={listings}
      cta={
        <Text as="p" sx={{ a: { variant: 'text.textLink' } }}>
          <Link to={'/shop/'}>{translate('product.related.cta')}</Link>
        </Text>
      }
    />
  )
}

export default PackRelatedListings
