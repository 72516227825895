/** @jsx jsx */
import { useState } from 'react'
import { jsx } from 'theme-ui'
import ListingAddToCartButton from '~/components/Listing/AddToCart/Button'
import ListingAddToCartKitSelector from '~/components/Listing/AddToCart/KitSelector'
import { getAvailablePackKits } from '~/utils/packs'

const PackDetailsAddToCart = ({ pack }) => {
  const { comingSoon, preorderDate, soldOut } = pack

  const availableKits = getAvailablePackKits(pack)
  const [currentKit, setCurrentKit] = useState(availableKits[0])
  const { sku } = currentKit

  return (
    <ListingAddToCartKitSelector
      allKits={availableKits}
      currentKit={currentKit}
      setCurrentKit={setCurrentKit}
      unavailable={soldOut || comingSoon}
    >
      <ListingAddToCartButton
        checkPostPromoPriceCodes={true}
        comingSoon={comingSoon}
        preorderDate={preorderDate}
        showPrice={true}
        sku={sku}
        soldOut={soldOut}
      />
    </ListingAddToCartKitSelector>
  )
}

export default PackDetailsAddToCart
