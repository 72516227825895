import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import PackPage from '~/components/Pack/Page'
import StructuredPackData from '~/components/StructuredData/Pack'
import useBanners from '~/hooks/components/use-banners'

const PackTemplate = ({ data }) => {
  const { pack } = data
  const { mainImage, name, noindex, shortDescription, slug } = pack

  const banner = useBanners(slug)

  return (
    <Layout banner={banner}>
      <Metadata
        title={name}
        description={shortDescription}
        image={getSrc(mainImage)}
        noindex={noindex}
      />
      <StructuredPackData pack={pack} />
      <PackPage pack={pack} />
    </Layout>
  )
}

export const query = graphql`
  query PackQuery($slug: String, $locale: String) {
    pack: contentfulPack(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PackPageFragment
    }
  }
`

export default PackTemplate
