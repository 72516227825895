/** @jsx jsx */
import { Fragment } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Heading, jsx } from 'theme-ui'
import SideBySideGrid from '~/components/Content/Section/SideBySideGrid'
import ProductTags from '~/components/Product/Tags'
import { useCatalog } from '~/hooks/components/use-catalog'
import { getPackUrl, getUniquePackProducts } from '~/utils/packs'

const PackProductsInfoMixedProduct = ({ index, pack, product }) => {
  const { mainImage, kitDescription, name, subtitle, tags } = product

  return (
    <SideBySideGrid
      swapOrder={!(index % 2)}
      first={
        mainImage && (
          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt={mainImage.description}
          />
        )
      }
      second={
        <Fragment>
          <Heading as="h3" mb={1} variant="title3">
            {name}
          </Heading>
          {subtitle && (
            <Heading as="h4" mb={3} variant="subtitle2">
              {subtitle}
            </Heading>
          )}
          {tags && <ProductTags tags={tags} />}
          {kitDescription && (
            <Box
              my={3}
              dangerouslySetInnerHTML={{
                __html: kitDescription.childMarkdownRemark.html,
              }}
              sx={{
                '& p': {
                  m: 0,
                  variant: 'text.body2',
                },
              }}
            />
          )}
          {pack && (
            <Link
              key="index"
              to={getPackUrl(pack)}
              sx={{
                variant: 'text.textLink',
              }}
            >
              {`Shop ${name}`}
            </Link>
          )}
        </Fragment>
      }
    />
  )
}

const PackProductsInfoMixed = ({ pack }) => {
  const { catalog } = useCatalog()
  const uniqueProducts = getUniquePackProducts(pack)

  return (
    <Box>
      {uniqueProducts.map((product, index) => (
        <Box
          key={`mixed-pack-product-${product.slug}-${index}`}
          bg={index % 2 === 0 ? 'background' : 'backgroundMedium'}
          py={[7, 9]}
        >
          <Container>
            <PackProductsInfoMixedProduct
              index={index}
              pack={catalog.packs.find(
                (pack) =>
                  pack.packType === 'Varietal' &&
                  pack?.kits?.[0]?.products?.[0]?.slug === product.slug
              )}
              product={product}
            />
          </Container>
        </Box>
      ))}
    </Box>
  )
}

export default PackProductsInfoMixed
