/** @jsx jsx */
import moment from 'moment'
import { getSrc } from 'gatsby-plugin-image'
import { jsx } from 'theme-ui'
import StructuredData from '~/components/StructuredData'
import { useReviews } from '~/hooks/components/use-reviews'
import { getDiscountedPrice } from '~/utils/packs'

const SITEWIDE_DISCOUNT = process.env.GATSBY_SITEWIDE_DISCOUNT

const StructuredPackData = ({ pack }) => {
  const { gtin, kits, mainImage, name, shortDescription, slug, soldOut } = pack

  const { getProductStats } = useReviews()

  // @see https://developers.google.com/search/docs/appearance/structured-data/product
  return (
    <StructuredData
      type="Product"
      data={{
        name: name,
        image: getSrc(mainImage),
        description: shortDescription,
        sku: kits?.[0]?.sku,
        gtin14: gtin,
        offers: {
          '@type': 'Offer',
          availability: soldOut ? 'SoldOut' : 'InStock',
          itemCondition: 'NewCondition',
          price: String(
            (
              (SITEWIDE_DISCOUNT &&
                getDiscountedPrice(SITEWIDE_DISCOUNT, kits?.[0]?.price)) ||
              kits?.[0]?.postPromoPrice ||
              kits?.[0]?.price
            )?.toFixed(2)
          ),
          priceCurrency: 'USD',
          priceValidUntil: moment().add(1, 'year').format('YYYY-MM-DD'),
        },
      }}
      rating={getProductStats(slug)}
    />
  )
}

export default StructuredPackData
