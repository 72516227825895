/** @jsx jsx */
import { Fragment } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Heading, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import SideBySideGrid from '~/components/Content/Section/SideBySideGrid'

const PackProductsInfoVarietal = ({ pack }) => {
  const translate = useTranslate()

  const {
    meetMakerDescription,
    meetMakerImage,
    meetMakerLink,
    meetWineDescription,
    meetWineImage,
  } = pack

  return (
    <Fragment>
      {meetWineImage && meetWineDescription && (
        <Box py={(7, 9)}>
          <Container>
            <SideBySideGrid
              swapOrder={true}
              first={
                <GatsbyImage
                  image={meetWineImage.gatsbyImageData}
                  alt={meetWineImage.description}
                />
              }
              second={
                <Fragment>
                  <Heading as="h3" mb={2} variant="title3">
                    {translate('product.meet_wine.title')}
                  </Heading>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: meetWineDescription.childMarkdownRemark.html,
                    }}
                    sx={{
                      '& p': {
                        variant: 'text.body2',
                      },
                    }}
                  />
                </Fragment>
              }
            />
          </Container>
        </Box>
      )}
      {meetMakerImage && meetMakerDescription && (
        <Box bg="backgroundMedium" py={[7, 9]}>
          <Container>
            <SideBySideGrid
              first={
                <GatsbyImage
                  image={meetMakerImage.gatsbyImageData}
                  alt={meetMakerImage.description}
                />
              }
              second={
                <Fragment>
                  <Heading as="h3" mb={2} variant="title3">
                    {translate('product.meet_maker.title')}
                  </Heading>
                  <Box
                    mb={3}
                    dangerouslySetInnerHTML={{
                      __html: meetMakerDescription.childMarkdownRemark.html,
                    }}
                    sx={{
                      '& p': {
                        variant: 'text.body2',
                      },
                    }}
                  />
                  <Fragment>
                    {meetMakerLink && (
                      <Link
                        key="meetMakerLink"
                        to={`/blog/${meetMakerLink.slug}`}
                        sx={{
                          variant: 'text.textLink',
                        }}
                      >
                        {translate('product.meet_maker.link')}
                      </Link>
                    )}
                  </Fragment>
                </Fragment>
              }
            />
          </Container>
        </Box>
      )}
    </Fragment>
  )
}

export default PackProductsInfoVarietal
