/** @jsx jsx */
import { jsx, Text } from 'theme-ui'

const PackDetailsUpsell = ({ children }) => {
  return (
    <Text
      as="p"
      bg="backgroundMedium"
      px={[6, 4]}
      py={2}
      variant="callout1"
      sx={{
        textAlign: 'center',
        strong: {
          color: 'brandPrimary',
        },
      }}
    >
      {children}
    </Text>
  )
}

export default PackDetailsUpsell
