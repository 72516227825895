/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import FaqSection from '~/components/Content/Section/Faq'

const PackFaq = ({ faq }) => {
  if (!faq) {
    return <Fragment />
  }

  return (
    <FaqSection
      topic={faq}
      title="FAQ"
      description={{
        childMarkdownRemark: {
          html: '<p>Reach out to <a href="mailto:hq@makerwine.com">hq@makerwine.com</a> with any additional questions.</p>',
        },
      }}
    />
  )
}

export default PackFaq
