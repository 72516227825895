/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx } from 'theme-ui'
import ListingCardSpecs from '~/components/Listing/Card/Specs'
import { getUniquePackProducts } from '~/utils/packs'

const PackProductsInfoSpecs = ({ pack, packType }) => {
  const uniqueProducts = getUniquePackProducts(pack)

  return (
    <Box bg="backgroundMedium" pt={[6, 7, 9]} pb={[7, 8, 10]}>
      <Container>
        <Heading
          as="h2"
          variant="title2"
          mb={[5, 7]}
          sx={{ textAlign: 'center' }}
        >
          {packType === 'Event' && 'Tasting Kit Details'}
          {packType === 'Gift' && 'Gift Pack Details'}
        </Heading>
        <Grid columns={[1, 12, 2]}>
          {uniqueProducts.map((product, index) => {
            const { mainImage, kitDescription, name, slug, subtitle, tags } =
              product

            return (
              name && (
                <Box
                  key={`pack-product-${slug}-${index}`}
                  sx={{ gridColumn: [null, '2 / 12', 'auto'] }}
                >
                  <ListingCardSpecs
                    description={kitDescription}
                    image={mainImage}
                    subtitle={subtitle}
                    tags={tags}
                    title={name}
                  />
                </Box>
              )
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default PackProductsInfoSpecs
